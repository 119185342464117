import React, { useRef, useEffect, useState } from "react";
import Flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";

const DatePicker = ({ startingDate, onDateChange }) => {
  const startDate = new Date(
    Date.UTC(
      startingDate.getFullYear(),
      startingDate.getMonth(),
      startingDate.getDate(),
      0,
      0,
      0,
      0
    )
  );
  const [date, setDate] = useState(startDate);
  date.setUTCHours(0, 0, 0, 0);
  const datePickerRef = useRef(null);

  useEffect(() => {
    const flatpickrInstance = Flatpickr(datePickerRef.current, {
      defaultDate: date,
      onChange: (selectedDates) => {
        const tomorrow = new Date(
          Date.UTC(
            selectedDates[0].getFullYear(),
            selectedDates[0].getMonth(),
            selectedDates[0].getDate(),
            0,
            0,
            0,
            0
          )
        );

        setDate(tomorrow);
        if (onDateChange) {
          onDateChange(tomorrow);
        }
      },
    });

    return () => {
      if (flatpickrInstance) {
        flatpickrInstance.destroy();
      }
    };
  }, [date, onDateChange]);

  return (
    <div>
      <input
        ref={datePickerRef}
        type="text"
        placeholder="Select Date"
        style={{ width: "200px", padding: "5px", marginTop: "10px" }}
      />
    </div>
  );
};

export default DatePicker;
