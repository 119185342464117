import { gql } from "@apollo/client";

export const GET_VIDEOS = gql`
  query mediaCommunityVideosForExternalService(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: MediaCommunityVideoOrderByInput
    $filter: MediaCommunityVideoOrderedFilterInput
  ) {
    mediaCommunityVideosForExternalService(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      filter: $filter
    ) {
      edges {
        cursor
        node {
          id
          location {
            city
            country
          }
          species {
            id
            name
          }
          video {
            id
            createdAt
            contentUrl(size: ORIGINAL)
            thumbnailUrl
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_MEDIA_GALLERY = gql`
  query mediaViewerForExternalService(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: MediaViewerOrderByInput
    $filter: MediaViewerFilterInput
  ) {
    mediaViewerForExternalService(
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
      filter: $filter
    ) {
      edges {
        cursor
        node {
          id
          media {
            id
            createdAt
            ... on MediaImage {
              contentUrl(size: ORIGINAL)
              thumbnailUrl
            }
            ... on MediaVideo {
              contentUrl(size: ORIGINAL)
              thumbnailUrl
              quality
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        hasPreviousPage
        startCursor
      }
    }
  }
`;
