import React, { forwardRef, useState } from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";

const VideoComponent = forwardRef(({ path, id, metadata, isVideo }, ref) => {
  const [loading, setLoading] = useState(false);

  const downloadVideoAndMetadata = async () => {
    try {
      const zip = new JSZip();
      const videoResponse = await axios.get(path, { responseType: "blob" });
      const videoBlob = new Blob([videoResponse.data], { type: "video/mp4" });
      const metadataBlob = new Blob([JSON.stringify(metadata, null, 2)], {
        type: "application/json",
      });
      zip.file(`video-${id}.mp4`, videoBlob);
      zip.file(`metadata-${id}.json`, metadataBlob);
      const zipBlob = await zip.generateAsync({ type: "blob" });
      saveAs(zipBlob, `video-and-metadata-${id}.zip`);
    } catch (error) {
      console.error("Error downloading video or metadata:", error);
    }
  };

  const downloadImageOrVideo = async () => {
    try {
      const response = await axios.get(path, {
        responseType: "blob",
      });
      let blob;
      if (isVideo) {
        blob = new Blob([response.data], { type: "video/mp4" });
      } else {
        blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
      }
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        isVideo ? `video-${id}.mp4` : `image-${id}.jpg`
      );

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(`Error downloading ${isVideo ? "video" : "image"}:`, error);
    }
  };

  return (
    <div className="video-item">
      {isVideo ? (
        <video
          ref={ref}
          key={`video-${id}`}
          controls
          width="250"
          onPlay={() => setLoading(true)}
          onPause={() => setLoading(false)}
          onEnded={() => setLoading(false)}
          src={path}
          onError={() => {
            setLoading(false);
            console.error(`Error loading video at URL: ${path}`);
          }}
        ></video>
      ) : (
        <img key={`img-${id}`} width="250" alt={`img-${id}`} src={path}></img>
      )}

      {
        <button
          className="button button-green"
          onClick={metadata ? downloadVideoAndMetadata : downloadImageOrVideo}
          target="_blank"
          rel="noreferrer"
        >
          {metadata
            ? "Download Video and Metadata"
            : isVideo
            ? "Download Video"
            : "Download Image"}
        </button>
      }

      {loading && (
        <div
          style={{
            position: "relative",
          }}
        >
          <div className="spinner"></div>
        </div>
      )}
    </div>
  );
});

export default VideoComponent;
