import HomePage from "./pages/HomePage";
import MediaGalleryPage from "./pages/MediaGalleryPage";

const routes = [
  { path: "/", name: "Home", element: <HomePage /> },
  {
    path: "/media-gallery",
    name: "Media Gallery",
    element: <MediaGalleryPage />,
  },
];

export default routes;
