// const rickAndMortApiURL = "https://rickandmortyapi.com/graphql";
export const myBirdBuddyProdApiUrl =
  "https://graphql.app-api.prod.aws.mybirdbuddy.com/graphql";
export const myBirdBuddyDevApiURL =
  "https://graphql.app-api.dev.aws.mybirdbuddy.com/graphql";

export const initialFeederSerialNumber = "00000052723";

export const VIDEO_STATE = {
  PREVIOUS: "PREVIOUS",
  NEXT: "NEXT",
};

export const enum FEEDER_VERSION {
  ALL = "ALL",
  V1 = "V1",
  V2 = "V2",
}

export const enum DAY {
  TODAY = "TODAY",
  TOMORROW = "TOMORROW",
}

export const enum QUERY_PARAM {
  FROM = "from",
  TO = "to",
  FEEDER_VERSION = "feederVersion",
  FEEDER_SERIAL_NUMBER = "feederSerialNumber",
  AFTER = "after",
  BEFORE = "before",
  FIRST = "first",
  LAST = "last",
  ASC = "ASC",
}
