import { useSearchParams } from "react-router-dom";
import { createUTCDate } from "../utils/dateUtils";
import {
  DAY,
  FEEDER_VERSION,
  initialFeederSerialNumber,
  QUERY_PARAM,
  VIDEO_STATE,
} from "../constants/consts";
import React, { useState, useRef, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_MEDIA_GALLERY, GET_VIDEOS } from "../GraphQL/Queries";
import DatePicker from "./Datepicker";
import VideoComponent from "./Video";

const MediaCommonLayout = ({ isHomePage = true }) => {
  const today = new Date();
  const todayUTC = createUTCDate(today);
  const nextDayUTC = createUTCDate(today, DAY.TOMORROW);
  const fromIsoDate = todayUTC.toISOString();
  const toIsoDate = nextDayUTC.toISOString();
  const itemsPerPage = 15;

  const [searchParams, setSearchParams] = useSearchParams();
  const [date, setDate] = useState(
    () => new Date(searchParams.get(QUERY_PARAM.FROM) || todayUTC)
  );
  const [pageInfo, setPageInfo] = useState(null);
  const [videoEdges, setVideoEdges] = useState([]);
  const [after, setAfter] = useState(
    searchParams.get(QUERY_PARAM.AFTER) || undefined
  );
  const [before, setBefore] = useState(
    searchParams.get(QUERY_PARAM.BEFORE) || undefined
  );
  const [skipQuery, setSkipQuery] = useState(true);
  const [feederVersion, setFeederVersion] = useState(
    searchParams.get(QUERY_PARAM.FEEDER_VERSION) || FEEDER_VERSION.ALL
  );
  const [feederSerialNumber, setFeederSerialNumber] = useState(
    searchParams.get(QUERY_PARAM.FEEDER_SERIAL_NUMBER) ||
      initialFeederSerialNumber
  );
  const [serialNumberError, setSerialNumberError] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const [variables, setVariables] = useState(() => {
    const searchParamFrom = searchParams.get(QUERY_PARAM.FROM);
    const searchParamTo = searchParams.get(QUERY_PARAM.TO);
    const from = searchParamFrom ? searchParamFrom : fromIsoDate;
    const to = searchParamTo ? searchParamTo : toIsoDate;
    const feederVersion =
      searchParams.get(QUERY_PARAM.FEEDER_VERSION) || undefined;
    const feederSerialNumber =
      searchParams.get(QUERY_PARAM.FEEDER_SERIAL_NUMBER) ||
      initialFeederSerialNumber;
    const url = new URL(window.location.href);
    let keys = [];
    url.searchParams.forEach((_value, key) => {
      if (
        (isHomePage &&
          key !== QUERY_PARAM.FROM &&
          key !== QUERY_PARAM.TO &&
          key !== QUERY_PARAM.FEEDER_VERSION) ||
        (!isHomePage && key !== QUERY_PARAM.FEEDER_SERIAL_NUMBER)
      ) {
        keys.push(key);
      }
    });

    keys.forEach((k) => {
      url.searchParams.delete(k);
    });
    const newUrl = url.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
    let filter;
    if (isHomePage) {
      filter = { from, to, feederVersion };
    } else {
      filter = { feederSerialNumber };
    }

    let initialVariables = { filter };
    if (feederSerialNumber) {
      initialVariables.feederSerialNumber = feederSerialNumber;
    }

    const firstSearchParam = searchParams.get(QUERY_PARAM.FIRST);
    const afterSearchParam = searchParams.get(QUERY_PARAM.AFTER);
    const lastSearchParam = searchParams.get(QUERY_PARAM.LAST);
    const beforeSearchParam = searchParams.get(QUERY_PARAM.BEFORE);

    if (firstSearchParam) {
      initialVariables.first = parseInt(firstSearchParam) || itemsPerPage;
      initialVariables.after = afterSearchParam;
    } else if (lastSearchParam) {
      initialVariables.last = parseInt(lastSearchParam) || itemsPerPage;
      initialVariables.before = beforeSearchParam;
    } else {
      initialVariables.first = itemsPerPage;
    }

    if (feederVersion === undefined) delete initialVariables.feederVersion;
    if (feederSerialNumber === undefined && !isHomePage)
      delete initialVariables.filter;

    initialVariables.orderBy = { createdAt: QUERY_PARAM.ASC };

    return initialVariables;
  });

  const videoRefs = useRef([]);

  const handleDateChange = (newDate) => {
    const newFromUTCDate = createUTCDate(newDate);
    const newToUTCDate = createUTCDate(newDate, DAY.TOMORROW);
    setDate(newFromUTCDate);

    const newFromIsoDate = newFromUTCDate.toISOString();
    const newToIsoDate = newToUTCDate.toISOString();

    setVariables((prev) => {
      let newFilter = {
        from: newFromIsoDate,
        to: newToIsoDate,
        feederVersion: feederVersion,
      };
      if (newFilter.feederVersion === FEEDER_VERSION.ALL)
        delete newFilter.feederVersion;
      const newVariables = {
        ...prev,
        filter: newFilter,
      };
      return newVariables;
    });

    setSearchParams((prevParams) => ({
      ...Object.fromEntries(prevParams),
      from: newFromIsoDate,
      to: newToIsoDate,
    }));
  };

  const handleSerialNumberChange = (event) => {
    const value = event.target.value;

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    setFeederSerialNumber(value);

    setDebounceTimeout(
      setTimeout(() => {
        if (!/^\d{10,11}$/.test(value) && value !== "") {
          setSerialNumberError(
            "Serial number must be a 10 or 11 digit number."
          );
        } else {
          setSerialNumberError("");
        }

        if (/^\d{10,11}$/.test(value)) {
          setVariables((prev) => ({
            ...prev,
            filter: { ...prev.filter, feederSerialNumber: value },
            feederSerialNumber: value,
          }));

          setSearchParams((prevParams) => ({
            ...Object.fromEntries(prevParams),
            feederSerialNumber: value,
          }));
        }
      }, 2000)
    );
  };

  const handleFeederVersionChange = (event) => {
    const feederValue = event.target.value;

    setFeederVersion(feederValue);
    setVariables((prev) => {
      const newFilter = {
        ...prev.filter,
        feederVersion:
          feederValue !== FEEDER_VERSION.ALL ? feederValue : undefined,
      };
      const newVariables = { ...prev, filter: newFilter };

      const newSearchParams = { ...Object.fromEntries(searchParams) };
      if (feederValue !== FEEDER_VERSION.ALL) {
        newSearchParams.feederVersion = feederValue;
      } else {
        delete newSearchParams.feederVersion;
      }
      setSearchParams(newSearchParams);

      return newVariables;
    });
  };

  const { error, data, loading } = useQuery(
    isHomePage ? GET_VIDEOS : GET_MEDIA_GALLERY,
    {
      variables,
      skip: skipQuery,
      fetchPolicy: "network-only",
      errorPolicy: "all",
    }
  );

  useEffect(() => {
    if (variables && skipQuery) {
      setSkipQuery(false);
    }
  }, [variables, skipQuery]);

  useEffect(() => {
    if (data?.mediaCommunityVideosForExternalService) {
      const { edges, pageInfo } = data.mediaCommunityVideosForExternalService;
      setVideoEdges(edges);
      setPageInfo(pageInfo);
      setAfter(pageInfo?.endCursor || undefined);
      setBefore(pageInfo?.startCursor || undefined);
    } else if (data?.mediaViewerForExternalService) {
      const { edges, pageInfo } = data.mediaViewerForExternalService;
      setVideoEdges(edges);
      setPageInfo(pageInfo);
      setAfter(pageInfo?.endCursor || undefined);
      setBefore(pageInfo?.startCursor || undefined);
    }
  }, [data]);

  const handlePageClick = (videoType) => {
    setVariables((prev) => {
      let newVariables = { ...prev };
      if (videoType === VIDEO_STATE.NEXT && pageInfo?.hasNextPage) {
        newVariables = {
          ...prev,
          after,
          first: itemsPerPage,
          before: undefined,
          last: undefined,
        };
        setSearchParams((prevParams) => {
          const newSearchParams = {
            ...Object.fromEntries(prevParams),
            first: itemsPerPage,
            after,
          };
          delete newSearchParams.before;
          delete newSearchParams.last;
          return newSearchParams;
        });
      } else if (
        videoType === VIDEO_STATE.PREVIOUS &&
        pageInfo?.hasPreviousPage
      ) {
        newVariables = {
          ...prev,
          before,
          last: itemsPerPage,
          first: undefined,
          after: undefined,
        };
        setSearchParams((prevParams) => {
          const newSearchParams = {
            ...Object.fromEntries(prevParams),
            last: itemsPerPage,
            before,
          };
          delete newSearchParams.after;
          delete newSearchParams.first;
          return newSearchParams;
        });
      }
      return newVariables;
    });
  };

  const handlePlayVideos = () => {
    videoRefs.current.forEach((video) => {
      if (video) {
        video.play().catch(console.error);
      }
    });
  };

  const isMediaTypeVideo = (node) => {
    return node?.media.hasOwnProperty("quality");
  };

  if (error) {
    console.error("Error loading videos: ", error);
  }

  return (
    <section
      id="video-container"
      className="flex flex-col items-center justify-center text-center bg-[#282c34] py-20 px-16 sm:px-4 sm:py-10 min-h-[100vh] mx-auto w-full relative"
    >
      <header className="flex flex-col gap-10 items-center justify-center max-w-[1440px] mx-auto w-full relative">
        <section className="filter-container">
          {isHomePage ? (
            <div
              id="calendar-dropdown"
              className="absolute top-20 right-16 sm:top-10 sm:right-4"
            >
              <DatePicker startingDate={date} onDateChange={handleDateChange} />
            </div>
          ) : (
            <div className="input-wrapper">
              <label htmlFor="input-wrapper-select">Serial Number:</label>
              <input
                className="input"
                type="number"
                value={feederSerialNumber}
                onChange={handleSerialNumberChange}
                style={{ borderColor: serialNumberError ? "red" : "green" }}
              />
              {serialNumberError && (
                <span className="error-wrapper">{serialNumberError}</span>
              )}
            </div>
          )}

          <div className="camera-version-filter">
            {isHomePage && (
              <>
                <label
                  className="label-camera-filter"
                  htmlFor="camera-filter-select"
                >
                  {isHomePage ? "Feeder Version" : "Media Type"} :
                </label>
                <select
                  style={{ cursor: "pointer", display: "inline-flex" }}
                  id="camera-filter-select"
                  value={feederVersion}
                  onChange={handleFeederVersionChange}
                >
                  {Object.values(FEEDER_VERSION).map((filterValue, index) => (
                    <option key={index} value={filterValue}>
                      {filterValue}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        </section>
        <div className="title-wrapper">
          <h1 className="title font-sans font-bold text-white text-5xl sm:text-xl">
            {isHomePage ? "Videos" : "Media Gallery"}
          </h1>
          <button
            className="button button-lightGreen"
            onClick={() => handlePlayVideos()}
            style={{
              zIndex: 9999999,
              position: "relative",
              backgroundColor:
                !videoEdges?.length === 0 && !loading ? "#d3d3d3" : "",
              cursor:
                !videoEdges?.length === 0 && !loading
                  ? "not-allowed"
                  : "pointer",
            }}
            disabled={!videoEdges?.length === 0}
          >
            Play all videos
          </button>
        </div>
      </header>
      <main>
        <section className="videos-section !flex !flex-wrap !gap-10 !overflow-clip !justify-center !items-center">
          {!loading &&
            videoEdges?.map((item, index) => (
              <VideoComponent
                key={index}
                id={item.node.id}
                path={
                  isHomePage
                    ? item.node.video.contentUrl
                    : item.node.media.contentUrl
                }
                isVideo={!isHomePage ? isMediaTypeVideo(item.node) : true}
                ref={(el) => (videoRefs.current[index] = el)}
                metadata={
                  isHomePage
                    ? {
                        id: item.node.id,
                        title: item.node.title,
                        location: { ...item.node?.location },
                        species: { ...item.node?.species },
                        video: { ...item.node?.video },
                      }
                    : null
                }
              />
            ))}
          {!loading && videoEdges?.length === 0 && (
            <p
              style={{
                zIndex: 9999999,
                position: "relative",
                color: "#f44336",
                fontSize: "20px",
                lineHeight: "28px",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              No videos for these variables!
            </p>
          )}
        </section>
        <section className="pagination flex mt-10">
          <button
            className="button button-blue"
            onClick={() => handlePageClick("PREVIOUS")}
            style={{
              zIndex: 9999999,
              position: "relative",
              backgroundColor: !pageInfo?.hasPreviousPage ? "#d3d3d3" : "",
              cursor: !pageInfo?.hasPreviousPage ? "not-allowed" : "pointer",
            }}
            disabled={!pageInfo?.hasPreviousPage}
          >
            Previous
          </button>
          <button
            className="button button-red"
            onClick={() => handlePageClick("NEXT")}
            style={{
              zIndex: 9999999,
              position: "relative",
              backgroundColor: !pageInfo?.hasNextPage ? "#d3d3d3" : "",
              cursor: !pageInfo?.hasNextPage ? "not-allowed" : "pointer",
            }}
            disabled={!pageInfo?.hasNextPage}
          >
            Next
          </button>
        </section>
      </main>
    </section>
  );
};

export default MediaCommonLayout;
