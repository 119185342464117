import { DAY } from "../constants/consts";

export const createUTCDate = (
  date: Date = new Date(),
  day: DAY = DAY.TODAY
) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      day === DAY.TODAY ? date.getDate() : date.getDate() + 1
    )
  );
};
